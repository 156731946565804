<template>
  <v-card id="cw-confirmation-dialog--dialog__businessLoanSigness__ownerInfo">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        The beneficial owner
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p v-translate>
        The beneficial owner must fulfill at least one of the following criteria:
      </p>
      <ul>
        <li v-translate>
          The individual owns more than 25% of the shares of the company, either directly or
          indirectly through another company.
        </li>
        <li v-translate>
          A person holds more than 25% of the voting rights in a company, either directly or
          indirectly through another company.
        </li>
        <li v-translate>
          The person otherwise has control of the company (eg through a shareholder agreement).
        </li>
      </ul>

      <p v-translate>
        In the absence of persons meeting the above criteria, the beneficiaries shall be members of
        the Board of Directors, responsible partners, the CEO or any other person in a similar
        position.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          $emit('dialog-cancel');
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Close
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CwDialogOwnerInfo',
};
</script>
